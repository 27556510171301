import React from 'react';
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from 'react-hook-form';
import { TextField, Typography, Box } from '@mui/material';

type IProps = {
  isRequired?: boolean;
  register: UseFormRegister<any>;
  clearErrors: UseFormClearErrors<any>;
  errors: FieldErrors<any>;
  label: string;
  fieldName: string;
  setError: UseFormSetError<any>;
  onChange?: (e: any) => void;
  mt?: '0';
  hideCustomLabel?: boolean;
  onlyPdf?: boolean;
  onlyImage?: boolean;
  fileLimitSize?: number;
};

const FileValidation = ({
  isRequired,
  register,
  clearErrors,
  errors,
  label,
  fieldName,
  setError,
  onChange,
  mt,
  hideCustomLabel,
  onlyImage,
  onlyPdf,
  fileLimitSize,
}: IProps) => {
  const validateFile = (fileList: FileList | undefined) => {
    const fileSize = fileLimitSize;
    const file = fileList?.[0];
    if (file && file.size > (fileSize || 5) * 1024 * 1024) {
      return `File size exceeds ${fileSize || 5} MB.`;
    }
    return true;
  };
  return (
    <Box sx={{ mt: mt ? 0 : 2 }}>
      {!hideCustomLabel && (
        <label>
          {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
        </label>
      )}

      <TextField
        id={`file-input-${fieldName}`}
        variant='outlined'
        type='file'
        label={hideCustomLabel ? label : ''}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: {
            accept: onlyImage
              ? 'image/jpeg,image/png,image/jpg'
              : onlyPdf
              ? 'application/pdf'
              : '',
          },
        }}
        fullWidth
        {...register(fieldName, {
          validate: validateFile,
          ...(isRequired && { required: 'File is required.' }),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0];
            if (file && file.size <= (fileLimitSize || 5) * 1024 * 1024) {
              onChange && onChange(file);
              clearErrors(fieldName);
            } else {
              setError(fieldName, {
                type: 'validate',
                message: `File size exceeds ${fileLimitSize || 5} MB.`,
              });
            }
          },
        })}
      />
      {errors[fieldName] && (
        <Typography color='error' variant='body2'>
          {errors[fieldName]?.message as string}
        </Typography>
      )}
    </Box>
  );
};

export default FileValidation;
