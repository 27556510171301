import { Typography, Divider, Card } from '@mui/material';
import { useCompanyOwnerQuery } from '../../../rest/useCompanyInfo';
import CardCompanyOwner from '../card-components/CardCompanyOwner';
import { AddCompanyOwner } from '../components/AddCompanyOwner';

const CompanyOwnerIndex = ({ isApplied }: { isApplied: number }) => {
  const { companyOwnerData, isLoading } = useCompanyOwnerQuery();

  return (
    <>
      <Card>
        <Typography variant='h3' sx={{ p: 2 }}>
          Company Owner's
        </Typography>

        <Divider light />
        {companyOwnerData?.length > 0 ? (
          <CardCompanyOwner
            data={companyOwnerData}
            loading={isLoading}
            isApplied={isApplied}
          />
        ) : (
          <AddCompanyOwner />
        )}
      </Card>
    </>
  );
};

export default CompanyOwnerIndex;
