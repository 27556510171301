import CardCompanyOProposer from '../card-components/CardCompanyProposer';
import AddCompanyProposerInfo from '../components/AddCompanyProposerInfo';
import { useCompanyProposer } from '../../../rest/useCompanyInfo';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { Typography, Divider, Card } from '@mui/material';

const ProposerIndex = () => {
  const { user } = useAuthContext();
  const { companyProposer, isLoading: proposerLoading } = useCompanyProposer(
    user.user_member_id.toString()
  );

  return (
    <>
      <Card>
        <Typography variant='h3' sx={{ p: 2 }}>
          Company proposer
        </Typography>
        <Divider light />
        {companyProposer.length > 0 ? (
          <CardCompanyOProposer
            data={companyProposer}
            loading={proposerLoading}
          />
        ) : (
          <AddCompanyProposerInfo />
        )}
      </Card>
    </>
  );
};

export default ProposerIndex;
