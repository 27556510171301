import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../../../../../rest/utils/api-endpoints';
import { HttpClient } from '../../../../../rest/utils/http';
import { IResponse } from '../../../../../rest/types';

export type IPayemntListType = {
  amount: string;
  bank_ac_no: string;
  bank_name: string;
  bank_slip: string;
  branch_name: string;
  deposit_date: string;
  id: number;
  invoice_id: number;
  member_id: number;
  payment_date: string;
  reference_no: string;
  related_id: number;
  service_type: string;
  status: string;
  type: 'online' | 'offline';
};

export interface IPaymentDataResponse extends IResponse {
  data?: IPayemntListType;
}
export const GetPaymentDataFun = async (params: any) => {
  return await HttpClient.get<IPaymentDataResponse>(
    `${API_ENDPOINTS.GET_PAYEMNT_DATA}`,
    params
  );
};

export function usePaymentDataQuery(params: any) {
  const { data, isLoading, error } = useQuery<IPaymentDataResponse, Error>(
    [API_ENDPOINTS.GET_PAYEMNT_DATA, { params }],
    () => GetPaymentDataFun(params),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: data?.data ?? ({} as IPayemntListType),
    isLoading,
    error,
  };
}
