import React, { useState } from 'react';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import {
  Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CardCompanyTradeLicense from '../card-components/CardCompanyTradeLicense';
import AddTradeLicenseInfo from '../components/AddTradeLicense';
import { useCompanyLicense } from '../../../rest/useCompanyInfo';
import UpdateCompanyTradeLicense from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyTradeLicense';

const TradeLicenseIndex = () => {
  const { user } = useAuthContext();
  const { companyLicense, isLoading } = useCompanyLicense();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3' sx={{ p: 2 }}>
            Trade License & TIN Certificate Information
          </Typography>

          <>
            {!user?.is_applied && companyLicense.company_trade_license ? (
              <Tooltip title='Update' sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </>
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateCompanyTradeLicense
            companyLicense={companyLicense}
            memberId={user.user_member_id}
          />
        ) : (
          <>
            {Object.keys(companyLicense).length > 0 ? (
              <CardCompanyTradeLicense
                key={5}
                data={companyLicense}
                loading={isLoading}
              />
            ) : (
              <AddTradeLicenseInfo />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default TradeLicenseIndex;
