import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { showToast } from '../../../Toaster/Toaster';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import AddressSelect from '../../address-change-application/AddressSelect';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { AddCompanyInfo } from '../../../ReactQuery/Mutations/CompanyAddMutation';
import FileValidation from '../../../Components/FileValidation/FileValidation';

const AddCompanyInformation = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  console.log(user, '---------');
  const queryClient = useQueryClient();
  const [zone, setZone] = useState<number>(
    (user?.user_member_zone_id as number) || 1
  );
  const [areaID, setAreaID] = useState<string>('');
  const [companyLogo, setCOmpanyLogo] = useState('');

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return AddCompanyInfo(data);
    },

    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_DETAILS],
      });
    },
  });

  const onSubmit = async (values: any) => {
    const { organization_logo, ...data } = values;

    if (!areaID) {
      showToast(`Please select division, district, thana and area`, 'error');
      return;
    }
    data.zone = zone;
    data.company_type = 'Travel agency';
    const formData = new FormData();
    formData.append('area_id', areaID);
    formData.append('company_logo', companyLogo);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    console.log(data);
    mutation.mutate(formData);
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      sx={{ p: 2 }}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <FormControl fullWidth variant='outlined'>
              <InputLabel
                sx={{ overflow: 'hidden' }}
                id='demo-simple-select-label'
              >
                Select zone
              </InputLabel>

              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={zone}
                required
                label='Select zone'
                placeholder='Select zone'
                onChange={(e: any) => setZone(e.target.value)}
              >
                <MenuItem value={1}>Dhaka</MenuItem>
                <MenuItem value={2}>Chittagong</MenuItem>
                <MenuItem value={3}>Sylhet</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <TextField
              id='outlined-basic'
              variant='outlined'
              placeholder='Enter organization type'
              inputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={<>Organization type</>}
              defaultValue={'Travel agency'}
              type='text'
              fullWidth
              required
              // {...register("company_type")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {' '}
          <Box>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label={<>Organization established date </>}
              id='date'
              type='date'
              required
              // value={date}
              {...register('stablished_date', {
                required: 'Stablished Date is required',
              })}
              error={errors.stablished_date && Boolean(errors.stablished_date)}
              helperText={
                errors.stablished_date?.message
                  ? `${errors.stablished_date?.message}`
                  : ''
              }
              sx={{ width: '100%' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              id='outlined-basic'
              variant='outlined'
              placeholder='Enter organization police station'
              type='text'
              required
              label='Which police station does the organization belong to?'
              fullWidth
              {...register('police_station', {
                required: 'Police station is required',
              })}
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.police_station && Boolean(errors.police_station)}
              helperText={
                errors.police_station?.message
                  ? `${errors.police_station?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              id='outlined-basic'
              variant='outlined'
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              label={<>Organization telephone number</>}
              required
              placeholder='Enter organization telephone number'
              fullWidth
              {...register('cellphone', {
                required: 'Organization telephone number is required',
              })}
              error={errors.cellphone && Boolean(errors.cellphone)}
              helperText={
                errors?.cellphone?.message
                  ? `${errors?.cellphone?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              required
              InputLabelProps={{
                shrink: true,
              }}
              label={<> Organization mobile number</>}
              id='outlined-basic'
              variant='outlined'
              {...register('phone', {
                required: 'Organization mobile number is required',
              })}
              error={errors.phone && Boolean(errors.phone)}
              helperText={
                errors.phone?.message ? `${errors.phone.message}` : ''
              }
              placeholder='Enter organization mobile number'
              type='text'
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label={<> Organization full address</>}
              id='outlined-basic'
              variant='outlined'
              placeholder='Enter organization full address'
              type='text'
              required
              fullWidth
              {...register('addressline1', {
                required: 'Organization full address is required',
              })}
              error={errors.addressline1 && Boolean(errors.addressline1)}
              helperText={
                errors?.addressline1?.message
                  ? `${errors?.addressline1?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label={<>Organization postal code</>}
              id='outlined-basic'
              variant='outlined'
              placeholder='Enter organization postal code'
              type='text'
              required
              fullWidth
              {...register('postalcode', {
                required: 'Postal code is required',
              })}
              error={errors.postalcode && Boolean(errors.postalcode)}
              helperText={
                errors?.postalcode?.message
                  ? `${errors?.postalcode?.message}`
                  : ''
              }
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <Box>
              <FileValidation
                mt={'0'}
                register={register}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                hideCustomLabel
                label='Upload organization logo ( max size 5mb )'
                fieldName={`organization_logo`}
                onChange={(e: any) => {
                  setCOmpanyLogo(e);
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label={<> Organization email address</>}
              id='outlined-basic'
              required
              variant='outlined'
              placeholder='Enter organization email address'
              type='email'
              fullWidth
              {...register('email', {
                required: 'Organization email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              error={errors.email && Boolean(errors.email)}
              helperText={
                errors?.email?.message ? `${errors?.email?.message}` : ''
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              required
              label={<>Organization website</>}
              id='outlined-basic'
              variant='outlined'
              placeholder='Enter organization website'
              type='url'
              fullWidth
              {...register('website')}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <AddressSelect setAreaID={setAreaID} isRequired={true} />
          </Grid>
        </Grid>
      </Grid>

      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
          {mutation.isLoading ? (
            <Button disabled variant='contained' disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type='submit' variant='contained' disableElevation>
              Save
            </Button>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AddCompanyInformation;
