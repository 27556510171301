import { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Chip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useCreateIDCard } from '../../rest/useCompanyIDCard';
import { useEffect } from 'react';
import CommonOfflinePaymentInfo from '../new-member-applications/IndexFile/paymentInformation/offline/CommonOfflinePaymentInfo';

type IProps = {
  handleClose: () => void;
};

const IdCardForm = ({ handleClose }: IProps) => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      bank_ac_no: '00890320000141',
      amount: '300',
      type: 'offline',
      bank_name: 'Trust Bank',
    },
  });
  const { isLoading, mutate, isSuccess } = useCreateIDCard();
  const [bloodGroup, setBloodGroup] = useState<string | undefined>('');

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const onSubmit = async (data: any) => {
    data.user_member_id = user.user_member_id;
    data.blood_group = bloodGroup;

    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    mutate(formData);
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Name of applicant'
              required
              id='outlined-required'
              type='text'
              {...register('applicant_name')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Designation'
              id='outlined-required'
              type='text'
              {...register('designation')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              required
              id='outlined-required'
              type='date'
              label='Date of birth'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('date_of_birth')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Blood group</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={bloodGroup}
                onChange={(e) => setBloodGroup(e.target.value)}
                label='Blood group'
              >
                <MenuItem value='a+'>A+</MenuItem>
                <MenuItem value='a-'>A-</MenuItem>
                <MenuItem value='ab+'>AB+</MenuItem>
                <MenuItem value='ab-'>AB-</MenuItem>
                <MenuItem value='b+'>B+</MenuItem>
                <MenuItem value='b-'>B-</MenuItem>
                <MenuItem value='o+'>O+</MenuItem>
                <MenuItem value='0-'>O-</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Telephone number'
              required
              id='outlined-required'
              type='text'
              {...register('telephone')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Cellphone number'
              required
              id='outlined-required'
              type='text'
              {...register('cellphone')}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Enter your email'
              required
              id='outlined-required'
              type='text'
              {...register('email')}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Photocopy of NID (Both side)'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('member_nid_file')}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='One copy passport size photography'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('photo')}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label='Signature with Seal'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('signature_file')}
            />
          </Grid>

          <Grid xs={12} item sx={{ my: 2 }}>
            <Divider>
              <Chip label='Offline Payment Information' size='small' />
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <CommonOfflinePaymentInfo
              clearErrors={clearErrors}
              setError={setError}
              errors={errors}
              register={register}
              md={4}
              lg={4}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!isLoading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default IdCardForm;
