import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { IMemberInfo } from './CompanyInfoTypes';

interface ICompanyInfoProp {
  memberInfo: IMemberInfo | undefined;
  loading: boolean;
  // errorMessage: string;
}

const CompanyInfo = ({
  memberInfo,
  loading,
}: // errorMessage,
ICompanyInfoProp) => {
  const {
    addressline1,
    company_ownership_status,
    email,
    first_proposer,
    phone,
    police_station,
    second_proposer,
    user_member_account_status,
    user_member_company_name,
    user_member_representative_name,
    zone_name,
    member_company_website,
  } = memberInfo || {};

  return (
    <div>
      <div className='box-container'>
        <h2>Company Information</h2>
        {loading ? (
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {!memberInfo?.user_member_company_name ? (
              <Box sx={{ textAlign: 'center', p: 5 }}>
                <Typography p={3} variant='h4' gutterBottom>
                  Update your information
                </Typography>
                <Link to='/new-member-applications'>
                  <Button variant='contained' sx={{ fontSize: 12 }}>
                    Click here
                  </Button>
                </Link>
              </Box>
            ) : (
              <div className='mt-4 company-info'>
                <ul>
                  <li>
                    <span>Organization Name</span>:{' '}
                    <p>
                      {user_member_company_name
                        ? user_member_company_name
                        : 'Not provided'}
                    </p>{' '}
                  </li>
                  <li>
                    <span>Representative Name</span>:{' '}
                    <p>
                      {user_member_representative_name
                        ? user_member_representative_name
                        : 'Not provided'}
                    </p>{' '}
                  </li>

                  <li>
                    <span>Organization Ownership Status</span>:{' '}
                    <p>
                      {company_ownership_status
                        ? company_ownership_status
                        : 'Not provided'}
                    </p>{' '}
                  </li>
                  <li>
                    <span>Account Status</span>:
                    <p
                      className={
                        user_member_account_status === 'pending'
                          ? 'pending'
                          : 'approved'
                      }
                    >
                      {user_member_account_status
                        ? user_member_account_status
                        : 'Not provided'}
                    </p>{' '}
                  </li>
                  <li>
                    <span>Full Address</span>:{' '}
                    <p>{addressline1 ? addressline1 : 'Not provided'}</p>{' '}
                  </li>
                  <li>
                    <span>Organization Zone</span>:{' '}
                    <p>{zone_name ? zone_name : 'Not provided'}</p>{' '}
                  </li>
                  <li>
                    <span>Police Station</span>:{' '}
                    <p>{police_station ? police_station : 'Not provided'}</p>{' '}
                  </li>
                  <li>
                    <span>Phone No</span>:{' '}
                    <p>{phone ? phone : 'Not provided'}</p>{' '}
                  </li>

                  <li>
                    <span>E-mail</span>: <p>{email ? email : 'Not provided'}</p>{' '}
                  </li>
                  <li>
                    <span>Website</span>:{' '}
                    <p>
                      <a
                        href={member_company_website}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {member_company_website
                          ? member_company_website
                          : 'Not provided'}
                      </a>
                    </p>
                  </li>
                  <li>
                    <span>1st Proposer</span>:{' '}
                    <p>
                      {first_proposer?.proposer_company_name
                        ? first_proposer.proposer_company_name
                        : 'Not provided'}
                    </p>{' '}
                  </li>
                  <li>
                    <span>2nd Proposer</span>:{' '}
                    <p>
                      {second_proposer?.proposer_company_name
                        ? second_proposer.proposer_company_name
                        : 'Not provided'}
                    </p>{' '}
                  </li>
                </ul>
              </div>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default CompanyInfo;
