import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import TableLoader from '../../Spinner/TableLoader';
import TableNoDataMessage from '../../TableMessage/TableNoDataMessage';
import moment from 'moment';
import ModalsWrapper from '../../Modals/ModalsWrapper';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { makeStyles } from '@mui/styles';
import { showToast } from '../../../Toaster/Toaster';
import { useDashboardMeetingQuery } from '../api/dashboardEndPoint';
import { useMutation, useQueryClient } from 'react-query';
import { PostJoinedMeeting } from '../api/dashboardApiFunction';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
export const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',
    '& td, & th': {
      padding: '3px',
      fontFamily: 'Poppins, sans-serif',
    },
  },
  container: {
    maxHeight: 400,
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  customScrollBar: {
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: `1px solid slategrey`,
    },
  },
});

const Meeting = () => {
  const { user } = useAuthContext();
  const { allMeeting, isLoading } = useDashboardMeetingQuery();
  const [open, setOpen] = useState(false);
  const [meetingId, setMeetingId] = useState<number>(0);
  const classes = useStyles();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return PostJoinedMeeting(data);
    },
    onSuccess: () => {
      showToast(`Successfully joined`, 'success');
      setOpen(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.JOINED_TRAINING],
      });
    },
  });

  const handelJoin = () => {
    mutation.mutate({
      meeting_id: meetingId,
      member_id: user.user_member_id,
    });
  };
  return (
    <>
      <TableContainer sx={{ height: '250px' }} className={classes.container}>
        <Table aria-label='simple table' className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Tittle</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {allMeeting?.length ? (
                  <>
                    {allMeeting?.map((sMeeting) => (
                      <TableRow key={sMeeting.member_meeting_id}>
                        <TableCell
                          sx={{
                            width: { xs: '160px', md: '250px', xl: '450px' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {sMeeting.member_meeting_title}
                        </TableCell>
                        <TableCell>
                          {moment(sMeeting.member_meeting_date).format('ll')}
                        </TableCell>

                        <TableCell>
                          {user?.user_member_account_status === 'active' ? (
                            <Button
                              size='small'
                              variant='contained'
                              onClick={() => {
                                if (!sMeeting.joined) {
                                  setOpen(true);
                                  setMeetingId(sMeeting.member_meeting_id);
                                }
                              }}
                            >
                              {sMeeting.joined ? 'Joined' : 'Join'}
                            </Button>
                          ) : (
                            <Button variant='contained' disabled>
                              Join
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNoDataMessage />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalsWrapper
        modalData={{ title: 'Join meeting' }}
        setShowModal={setOpen}
        showModal={open}
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Are you sure you want to join this meeting?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {mutation.isLoading ? (
            <Button color='primary' autoFocus disabled>
              Loading...
            </Button>
          ) : (
            <Button
              color='primary'
              variant='outlined'
              size='small'
              autoFocus
              onClick={handelJoin}
            >
              Yes
            </Button>
          )}

          <Button
            color='error'
            size='small'
            variant='outlined'
            onClick={() => setOpen(false)}
          >
            No
          </Button>
        </DialogActions>
      </ModalsWrapper>
    </>
  );
};

export default Meeting;
