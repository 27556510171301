import PrivateRoute from '../Components/PrivateRoute/PrivateRoute';
import DashboardHome from '../DashboardUtils/DashboardHome';
import ForgetPassword from '../Pages/Forget/ForgetPassword';
import Dashboard from '../Pages/Dashboard/Home/Dashboard';
import Payments from '../Pages/Payments/pages/Payments';
import { createBrowserRouter } from 'react-router-dom';
import NotFound from '../Pages/NotFound/NotFound';
import Register from '../Pages/Register/Register';
import ResetPassword from '../Pages/Reset/Reset';
import Login from '../Pages/Login/Login';
import PaymentSuccess from '../Pages/PaymentSuccess/PaymentSuccess';
import PaymentFailed from '../Pages/PaymentFailed/PaymentFailed';
import MatchOtp from '../Pages/MatchOtp/MatchOtp';
import NewPassword from '../Pages/NewPassword/NewPassword';
import ChangePassword from '../Pages/ChangePassword/ChangePassword';
import PaymentReject from '../Pages/PaymentReject/PaymentReject';
import ProposerRequest from '../Pages/ProposerRequest/ProposerRequest';
import ProposerReciver from '../Pages/ProposerReciver/ProposerReciver';
import JoinedMeeting from '../Pages/JoinedMeeting/JoinedMeeting';
import JoinedEvent from '../Pages/JoinedEvent/JoinedEvent';
import SingleEvent from '../Pages/JoinedEvent/SingleEvent';
import DownloadCertificate from '../Pages/CompanyUpdateInfo/singleMember/DownloadCertificate';
import IdCard from '../Pages/IdCard/IdCard';
import SingleIdCard from '../Pages/IdCard/SingleIdCard';
import Notifications from '../Pages/Notifications/Notifications';
import Profile from '../Pages/Profile/Profile';
import NewMemberApplications from '../Pages/new-member-applications/NewMemberApplications';
import RenewalApplication from '../Pages/renewal-application/RenewalApplication';
import OwnershipApplication from '../Pages/ownership-change/OwnershipApplication';
import AddressChangeApplication from '../Pages/address-change-application/AddressChangeApplication';
import DuplicateCertificateApplication from '../Pages/duplicate-certificate/DuplicateCertificateApplication';
import SingleDuplicateCertificateApplication from '../Pages/duplicate-certificate/SingleDuplicateCertificate';
import SingleAddressApplication from '../Pages/address-change-application/SingleAddressChange';
import SingleOwnershipChangeApplication from '../Pages/ownership-change/SingleOwnershipChange';
import SingleRenewalApplication from '../Pages/renewal-application/SingleRenewalApplication';
// import ProposerReceiver from "../Pages/proposer-receiver/ProposerReceiver";
import AgencyInfoUpdateApplication from '../Pages/AgencyInfoUpdateApplication/AgencyInfoUpdateApplication';
import SingleAgencyUpdateInfo from '../Pages/AgencyInfoUpdateApplication/SingleAgencyUpdateInfo';
import MemberDirectory from '../Pages/Directory/MemberDirectory';
import VerifyMember from '../Pages/VerifyMember/VerifyMember';

export const routers = createBrowserRouter([
  {
    path: '*',
    element: <NotFound />,
  },

  {
    path: '/',
    element: <PrivateRoute component={<Dashboard />} />,
    children: [
      {
        path: '/',
        element: <DashboardHome />,
      },
      {
        path: 'payments',
        element: <Payments />,
      },
      {
        path: 'new-member-applications',
        element: <NewMemberApplications />,
      },
      {
        path: 'renewal-applications',
        element: <RenewalApplication />,
      },
      {
        path: 'renewal-applications/:id',
        element: <SingleRenewalApplication />,
      },
      {
        path: 'address-change-applications',
        element: <AddressChangeApplication />,
      },
      {
        element: <SingleAddressApplication />,
        path: 'address-change-applications/:id',
      },
      {
        path: 'ownership-change-applications',
        element: <OwnershipApplication />,
      },
      {
        path: 'ownership-change-applications/:id',
        element: <SingleOwnershipChangeApplication />,
      },
      {
        path: 'duplicate-certificate-application',
        element: <DuplicateCertificateApplication />,
      },
      {
        path: 'duplicate-certificate-application/:id',
        element: <SingleDuplicateCertificateApplication />,
      },
      {
        path: 'agency-info-update-application',
        element: <AgencyInfoUpdateApplication />,
      },
      {
        path: 'agency-info-update-application/:id',
        element: <SingleAgencyUpdateInfo />,
      },
      // {
      //   path: "your-proposer-request",
      //   element: <ProposerReceiver />,
      // },

      { path: 'id-card-application', element: <IdCard /> },
      { path: 'id-card-application/:cardID', element: <SingleIdCard /> },
      { path: 'certificate', element: <DownloadCertificate /> },
      { path: 'notification', element: <Notifications /> },
      { path: 'proposer-requester', element: <ProposerRequest /> },
      { path: 'proposer-receiver', element: <ProposerReciver /> },
      { path: 'joined-meeting', element: <JoinedMeeting /> },
      { path: 'joined-event', element: <JoinedEvent /> },
      { path: 'joined-event/:eventId', element: <SingleEvent /> },
      { path: 'change-password', element: <ChangePassword /> },
      { path: 'profile', element: <Profile /> },
    ],
  },
  { path: 'payment-success', element: <PaymentSuccess /> },
  { path: 'payment-failed', element: <PaymentFailed /> },
  { path: 'payment-reject', element: <PaymentReject /> },

  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'registration',
    element: <Register />,
  },
  {
    path: 'reset',
    element: <ResetPassword />,
  },

  {
    path: '/forget-password',
    element: <ForgetPassword />,
  },
  {
    path: '/match-otp',
    element: <MatchOtp />,
  },
  {
    path: '/new-password',
    element: <NewPassword />,
  },
  {
    path: 'member-directory',
    element: <MemberDirectory />,
  },
  {
    path: 'verify-member',
    element: <VerifyMember />,
  },
]);
