import { useForm, useWatch } from 'react-hook-form';
import { Box, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import CommonOfflinePaymentInfo from './CommonOfflinePaymentInfo';

interface FormData {
  bank_ac_no: string;
  bank_name: string;
  amount: string;
  deposit_date: string;
  branch_name: string;
  reference_no: string;
  bank_slip: FileList | null;
  type: 'offline';
}
const areAllFieldsFilled = (values: Partial<FormData>) => {
  return Object.values(values).every(
    (value) => value !== null && value !== undefined && value !== ''
  );
};
const PaymentOfflineInfromation = ({
  setPaymentOfflineInformation,
}: {
  setPaymentOfflineInformation: React.Dispatch<
    React.SetStateAction<null | any>
  >;
}) => {
  const [showRequredMessage, setShowRequredMessage] = useState<string | null>(
    null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<FormData>({
    defaultValues: {
      bank_ac_no: '00890320000141',
      bank_name: 'Trust Bank',
      amount: '62300',
      deposit_date: '',
      branch_name: '',
      reference_no: '',
      bank_slip: null,
      type: 'offline',
    },
  });

  const allValue = useWatch({ control });
  const areFieldsValid = areAllFieldsFilled(allValue);
  useEffect(() => {
    if (areFieldsValid) {
      setPaymentOfflineInformation(allValue);
      setShowRequredMessage(null);
    } else {
      setShowRequredMessage(
        'All fields are mandatory. Please fill up all fields.'
      );
    }
  }, [areFieldsValid, allValue]);
  const onSubmit = (data: FormData) => {
    console.log('Form Data:', data);
  };

  return (
    <Card>
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            padding: '0px 18px',
            color: 'red',
          }}
        >
          {showRequredMessage && <span>{showRequredMessage}</span>}
        </Box>
        <Box
          sx={{
            padding: '20px',
            boxShadow: 3,
            borderRadius: 2,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CommonOfflinePaymentInfo
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              setError={setError}
              sm={4}
              md={4}
              lg={4}
            />
          </form>
        </Box>
      </Box>
    </Card>
  );
};

export default PaymentOfflineInfromation;
