import { Grid, InputAdornment, TextField } from '@mui/material';
import FileValidation from '../../../../../Components/FileValidation/FileValidation';

const CommonOfflinePaymentInfo = ({
  register,
  errors,
  clearErrors,
  setError,
  sm,
  md,
  lg,
}: {
  register: any;
  errors: any;
  clearErrors: any;
  setError: any;

  sm?: number;
  md?: number;
  lg?: number;
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <TextField
            {...register('bank_ac_no', {
              required: 'Account No is required',
            })}
            fullWidth
            disabled
            InputLabelProps={{ shrink: true }}
            value={'00890320000141'}
            label='Account No'
            variant='outlined'
            error={!!errors.bank_ac_no}
            helperText={errors.bank_ac_no?.message}
          />
        </Grid>

        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <TextField
            {...register('deposit_date', {
              required: 'Deposit Date is required',
            })}
            fullWidth
            label='Deposit Date'
            variant='outlined'
            type='date'
            InputLabelProps={{ shrink: true }}
            error={!!errors?.deposit_date}
            helperText={errors?.deposit_date?.message}
          />
        </Grid>
        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <TextField
            {...register('bank_name', {
              required: 'Bank Name is required',
            })}
            disabled
            defaultValue={'Trust Bank'}
            fullWidth
            value={'Trust Bank'}
            label='Bank Name'
            variant='outlined'
            error={!!errors?.bank_name}
            helperText={errors?.bank_name?.message}
          />
        </Grid>

        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <TextField
            {...register('branch_name', {
              required: 'Branch Name is required',
            })}
            fullWidth
            label='Branch Name'
            variant='outlined'
            error={!!errors?.branch_name}
            helperText={errors?.branch_name?.message}
          />
        </Grid>
        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <TextField
            {...register('amount', {
              required: 'Amount is required',
              min: { value: 1, message: 'Amount must be greater than 0' },
            })}
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
            label='Amount'
            variant='outlined'
            type='number'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>৳</InputAdornment>
              ),
            }}
            error={!!errors.amount}
            helperText={errors.amount?.message}
          />
        </Grid>

        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <FileValidation
            mt='0'
            fileLimitSize={3}
            isRequired
            register={register}
            clearErrors={clearErrors}
            setError={setError}
            errors={errors}
            label='Upload Bank Slip/Document ( max size 3mb )'
            fieldName={`bank_slip`}
            // onChange={(e: any) => {
            //   handleChangeInput(index, 'company_owner_photo', e);
            // }}

            hideCustomLabel
          />
        </Grid>
        <Grid item xs={12} sm={sm || 6} md={md || 6} lg={lg || 6}>
          <TextField
            {...register('reference_no', {
              required: 'Reference No is required',
            })}
            fullWidth
            label='Reference No'
            variant='outlined'
            error={!!errors?.reference_no}
            helperText={errors?.reference_no?.message}
          />
        </Grid>
        {/* <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <Button variant='contained' color='primary' type='submit'>
                  Save
                </Button>
              </Grid> */}
      </Grid>
    </>
  );
};

export default CommonOfflinePaymentInfo;
