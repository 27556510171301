import { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import OfflinePaymentInformation from './offline/OfflinePaymentInformation';
import { usePaymentDataQuery } from './api/paymentApi';
import OfflinePaymentCardInformation from './offline/OfflinePaymentCardInformation';
import { useAuthContext } from '../../../../Context/AuthContext/AuthContext';
const PaymentInformation = ({
  setPaymentOfflineInformation,
}: {
  setPaymentOfflineInformation: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const { user } = useAuthContext();
  const [showPaymentStatus, setShowPaymentStatus] = useState('offline_payment');
  const { data, isLoading } = usePaymentDataQuery({
    service_type: 'NEW_MEMBERSHIP_FEE',
    related_id: user.user_member_id,
  });
  return Object?.keys(data)?.length === 0 && user.is_applied ? (
    //old member  has no payment information. so dont show it here.
    <></>
  ) : (
    <Card
      sx={{
        marginBottom: '10px',
      }}
    >
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3' sx={{ p: 2 }}>
            Payment information
          </Typography>
        </Box>

        <Divider light />
        <FormControl sx={{ p: '18px 18px 0 18px' }}>
          <FormLabel
            id='demo-row-radio-buttons-group-label'
            sx={{ fontWeight: 'bold' }}
          >
            Select Payment Option
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            onChange={(_, e: string) => {
              setShowPaymentStatus(e);
              setPaymentOfflineInformation(e);
            }}
            value={showPaymentStatus}
          >
            <FormControlLabel
              value='offline_payment'
              control={<Radio />}
              label='Offline Payment'
            />
            {/* <FormControlLabel
        value='online_payment'
        control={<Radio />}
        label='Online Payment'
      /> */}
          </RadioGroup>
        </FormControl>

        {showPaymentStatus === 'offline_payment' && data?.type !== 'offline' ? (
          <OfflinePaymentInformation
            setPaymentOfflineInformation={setPaymentOfflineInformation}
          />
        ) : (
          <OfflinePaymentCardInformation loading={isLoading} data={data} />
        )}
      </Box>
    </Card>
  );
};

export default PaymentInformation;
