import Label from './Label/Label';

import travel from '../../src/assets/lotties/travels.json';
import traveler from '../../src/assets/lotties/travel-r.json';

export const LottiedefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: travel,
  speed: 1,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const LottiedefaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: traveler,
  speed: 1,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const getStatusLabel = (invoiceStatus: string) => {
  const map: any = {
    failed: {
      text: 'Failed',
      color: 'error',
    },
    completed: {
      text: 'Completed',
      color: 'success',
    },
    running: {
      text: 'Running',
      color: 'success',
    },
    finished: {
      text: 'Finished',
      color: 'success',
    },
    pending: {
      text: 'Pending',
      color: 'warning',
    },
    rejected: {
      text: 'Rejected',
      color: 'error',
    },
    active: {
      text: 'Active',
      color: 'success',
    },
    approved: {
      text: 'Approved',
      color: 'success',
    },
    ended: {
      text: 'Ended',
      color: 'warning',
    },
    upcoming: {
      text: 'Upcoming',
      color: 'warning',
    },
    cancelled: {
      text: 'Cancelled',
      color: 'warning',
    },
    available: {
      text: 'Available',
      color: 'success',
    },
    reserved: {
      text: 'Reserved',
      color: 'warning',
    },
    booked: {
      text: 'Booked',
      color: 'warning',
    },
    paid: {
      text: 'Paid',
      color: 'success',
    },
    unpaid: {
      text: 'Unpaid',
      color: 'warning',
    },
    reported: {
      text: 'Reported',
      color: 'warning',
    },
  };
  if ((map[invoiceStatus] && invoiceStatus !== null) || '') {
    const { text, color }: any = map[invoiceStatus];
    return <Label color={color}>{text}</Label>;
  } else if (invoiceStatus !== null || '') {
    return <Label>{invoiceStatus}</Label>;
  }
  return;
};
export const getExamStatusLabel = (ExamStatus: string) => {
  const map: any = {
    finished: {
      text: 'Finished',
      color: 'error',
    },

    upcoming: {
      text: 'Upcoming',
      color: 'warning',
    },
    unpaid: {
      text: 'Unpaid',
      color: 'warning',
    },
    paid: {
      text: 'paid',
      color: 'success',
    },
    cancelled: {
      text: 'Cancelled',
      color: 'error',
    },
  };
  if ((map[ExamStatus] && ExamStatus !== null) || '') {
    const { text, color }: any = map[ExamStatus];
    return <Label color={color}>{text}</Label>;
  } else if (ExamStatus !== null || '') {
    return <Label>{ExamStatus}</Label>;
  }
  return;
};

export type IInvoiceStatus =
  | 'pending'
  | 'cancelled'
  | 'reported'
  | 'paid'
  | 'rejected'
  | string;
export const getStatusLabelInvoiceStatus = (invoiceStatus: IInvoiceStatus) => {
  const map: any = {
    paid: {
      text: 'Paid',
      color: 'success',
    },
    reported: {
      text: 'Reported',
      color: 'warning',
    },
    rejected: {
      text: 'Rejected',
      color: 'warning',
    },
    pending: {
      text: 'Pending',
      color: 'warning',
    },
    cancelled: {
      text: 'Cancelled',
      color: 'warning',
    },
  };

  const { text, color }: any = map[invoiceStatus];
  return <Label color={color}>{text}</Label>;
};
