import React, { useState } from 'react';
import moment from 'moment';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { Box, Typography, Card, CardHeader, Divider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { IPayment } from '../type/PaymentsTypes';
import TableLoader from '../../../Components/Spinner/TableLoader';
import TableNoDataMessage from '../../../Components/TableMessage/TableNoDataMessage';
import { getStatusLabel } from '../../../Utils/Comibined';
import { useInvoiceQuery } from '../api/paymentEndPoint';
import PaymentDetailsModal from '../components/PaymentDetailsModal';

const Payments = () => {
  const { user } = useAuthContext();
  const [inVoiceId, setInvoiceId] = useState<number>(0);
  // const [value, setValue] = React.useState("pending");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { allPayments, isLoading } = useInvoiceQuery();
  // const payInvoice = async (invoiceId: number, totalAmount: number) => {
  //   const response = await axios.post(
  //     `${url}/api/atab-invoice/payment/make-a-payment`,
  //     {
  //       total_amount: totalAmount,
  //       invoice_id: invoiceId,
  //       customer_name: user.user_member_representative_name,
  //       customer_email: user.user_member_email,
  //       customer_phone: user.user_member_phone,
  //       product: 'membership',
  //     }
  //   );

  //   if (response.data.success) {
  //     window.location.replace(response.data.data.GatewayPageURL);
  //   } else {
  //     alert('Cannot pay online now!');
  //   }
  // };

  return (
    <Box mt={5} mb={5}>
      {/* <TabContext value={value}> */}
      <Card>
        <CardHeader
          title={
            <Typography
              sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}
            >
              Invoice Information
            </Typography>
          }
          // action={
          //   <TabList
          //     onChange={(_event: any, newValue: string) => setValue(newValue)}
          //     aria-label="lab API tabs example"
          //   >
          //     <Tab label="Pending" value="pending" />
          //     <Tab label="Paid" value="paid" />
          //     <Tab label="Cancelled" value="cancelled" />
          //     <Tab label="Reported" value="reported" />
          //   </TabList>
          // }
        />
        <Divider />

        <Box>
          {/* <TabPanel value={value}> */}
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>SL NO</TableCell>
                  <TableCell align='center'>Invoice No</TableCell>
                  <TableCell align='center'>Date</TableCell>
                  <TableCell align='center'>Amount</TableCell>
                  <TableCell align='center'>Status</TableCell>

                  <TableCell align='center'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableLoader />
                ) : (
                  <>
                    {allPayments.length ? (
                      <>
                        {allPayments.map((sPayment: IPayment, index) => (
                          <TableRow
                            key={sPayment.atab_payment_invoice_id}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align='center'>{index + 1}</TableCell>
                            <TableCell align='center'>
                              {sPayment.atab_payment_invoice_no}
                            </TableCell>
                            <TableCell align='center'>
                              {moment(
                                sPayment.atab_payment_invoice_issue_date
                              ).format('Do MMM YYYY')}
                            </TableCell>
                            <TableCell align='center'>
                              {sPayment.atab_payment_invoice_total_amount}
                            </TableCell>
                            <TableCell align='center'>
                              <p>
                                {getStatusLabel(
                                  Number(sPayment.atab_payment_invoice_due) > 0
                                    ? 'unpaid'
                                    : 'paid'
                                )}
                              </p>
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{ cursor: 'pointer' }}
                            >
                              <Button
                                variant='text'
                                onClick={() => {
                                  handleOpen();
                                  setInvoiceId(
                                    sPayment.atab_payment_invoice_id
                                  );
                                }}
                              >
                                View
                              </Button>
                            </TableCell>

                            {/* {sPayment.atab_payment_invoice_status ===
                                "pending" ? (
                                  <TableCell
                                    align="center"
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        payInvoice(
                                          sPayment.atab_payment_invoice_id,
                                          sPayment.atab_payment_invoice_total_amount
                                        );
                                      }}
                                    >
                                      pay
                                    </Button>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="center"
                                    sx={{ cursor: "pointer" }}
                                  >
                                    paid
                                  </TableCell>
                                )} */}
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableNoDataMessage />
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>

      <PaymentDetailsModal
        open={open}
        handleClose={handleClose}
        inVoiceId={inVoiceId}
        user={user}
      />
    </Box>
  );
};

export default Payments;
