import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { HttpClient } from '../../../rest/utils/http';
import { IPayment, IPaymentResponse } from '../type/PaymentsTypes';

const fetchPayment = async () => {
  const fetchData = await HttpClient.get<IPaymentResponse>(
    `${API_ENDPOINTS.INVOICE_LIST}`
  );
  return fetchData;
};

export function useInvoiceQuery() {
  const { data, isLoading, error } = useQuery<IPaymentResponse, Error>(
    [API_ENDPOINTS.INVOICE_LIST],
    () => fetchPayment(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allPayments: data?.data ?? ([] as IPayment[]),
    isLoading,
    error,
  };
}
export function useViewInvoiceQuery() {
  const { data, isLoading, error } = useQuery<IPaymentResponse, Error>(
    [API_ENDPOINTS.INVOICE_LIST],
    () => fetchPayment(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allPayments: data?.data ?? ([] as IPayment[]),
    isLoading,
    error,
  };
}
