import React, { useState } from 'react';
import CardCompanyInformation from '../card-components/CardCompanyInformation';
import AddCompanyInformation from '../components/AddCompanyInformation';
import { useCompanyDetailsQuery } from '../../../rest/useCompanyInfo';
import {
  Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateCompanyInformation from '../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyInformation';

const CompanyInformationIndex = ({ isApplied }: { isApplied: number }) => {
  const { companyData, isLoading } = useCompanyDetailsQuery();
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3' sx={{ p: 2 }}>
            Company information
          </Typography>

          <>
            {!isApplied && companyData.member_company_name ? (
              <Tooltip title='Update' sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ''
            )}
          </>
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateCompanyInformation companyData={companyData} />
        ) : (
          <>
            {companyData.member_company_name ? (
              <CardCompanyInformation loading={isLoading} data={companyData} />
            ) : (
              <AddCompanyInformation />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default CompanyInformationIndex;
