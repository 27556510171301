import { Box, Typography, Button, Container } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Link } from 'react-router-dom';

const PaymentFailed = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth='sm' sx={{ textAlign: 'center', padding: 4 }}>
        <ErrorOutlineOutlinedIcon
          sx={{
            fontSize: '100px',
            color: '#d32f2f',
            marginBottom: 2,
          }}
        />
        <Typography
          variant='h4'
          gutterBottom
          sx={{ fontWeight: 'bold', color: '#d32f2f' }}
        >
          Transaction Failed
        </Typography>
        <Typography
          variant='body1'
          sx={{
            marginBottom: 3,
            color: '#6c757d',
          }}
        >
          Something went wrong with your transaction.
        </Typography>
        <Link to={'/new-member-applications'}>
          <Button
            variant='contained'
            color='error'
            size='large'
            sx={{
              padding: '10px 30px',
              borderRadius: '30px',
              textTransform: 'none',
              fontSize: '16px',
            }}
          >
            Retry Transaction
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default PaymentFailed;
