import React, { useContext, useReducer, useEffect } from 'react';
import reducer from '../../Reducers/AuthReducer/AuthReducer';
import { IauthContextState } from './AuthTyps';
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
  SET_NEW_MESSAGE,
  url,
} from '../../Helpers/utils/Constant';
import * as jose from 'jose';
import { parseCookies } from 'nookies';
import socket from '../../Helpers/socket';
import axios from 'axios';
import { useState } from 'react';

const initialState: IauthContextState = {
  user: {
    user_member_id: 0,
    user_member_phone: '',
    user_member_company_name: '',
    user_member_account_status: '',
    user_member_representative_name: '',
    user_member_email: '',
    user_member_payment_verified_status: null,
    is_applied: 0,
    user_member_company_owner_name: '',
    user_member_representative_photo: '',
    user_member_zone_id: '',
  },
  notification: { notification: [], count: 0 },
  messages: [],
};

const AuthContext = React.createContext(initialState);
const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { atab_ua } = parseCookies();
      if (atab_ua) {
        try {
          const { payload } = await jose.jwtVerify(
            atab_ua,
            new TextEncoder().encode(
              `MemAT+JN!S@ruqz7$2R&h4QU(*d8Ab%CncTjPgk^mLeDXKfMHvVWwFpABbeR`
            )
          );
          const { data } = await axios.get(
            `${url}/api/atab/user-member/get/a/member/${payload.user_member_id}`
          );
          dispatch({ type: AUTH_USER_SUCCESS, payload: data.data });
          setIsLoading(false);
        } catch (err) {
          dispatch({ type: AUTH_USER_FAILED });
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (state.user?.user_member_id) {
      socket.auth = { id: state.user?.user_member_id, type: 'user_member' };
      if (!socket.connected) {
        socket.connect();
      }
      socket.on('new_message', (data) => {
        dispatch({ type: SET_NEW_MESSAGE, payload: data });
      });
    }
    return () => {
      socket.off('new_message');
      socket.disconnect();
    };
  }, [state.user]);

  return (
    <>
      <AuthContext.Provider
        value={{ ...state, dispatch, isLoading, setIsLoading }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
