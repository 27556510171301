import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useCompanyDocumentUpdate } from '../../../rest/useCompanyInfo';
import FormHelperText from '@mui/material/FormHelperText';
import FileValidation from '../../../Components/FileValidation/FileValidation';

const AddCompanyDocument = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError: formError,
    formState: { errors },
  } = useForm();
  const { isLoading, isSuccess, mutate } = useCompanyDocumentUpdate();
  const [ownershipStatus, setOwnershipStatus] = useState<string>('');
  const [officeAgreement, setOfficeAgreement] = useState<string>('');
  const [organizationOfficePhoto, setOrganizationOfficePhoto] = useState<any>(
    []
  );
  const [error, setError] = useState({
    ownershipStatus: false,
    officeAgreement: false,
  });
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setOrganizationOfficePhoto([]);
    }
  }, [isSuccess]);

  const onSubmit = (data: any) => {
    if (!ownershipStatus) {
      setError({
        ...error,
        ownershipStatus: true,
      });
      return;
    }
    if (!officeAgreement) {
      setError({
        ...error,
        officeAgreement: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('ownership_status', ownershipStatus);
    formData.append('office_agreement', officeAgreement);

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'object') {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });
    organizationOfficePhoto.forEach((img: string, index: number) => {
      const name = 'office_photographs' + (index + 1);
      formData.append(name, img);
    });
    mutate({ input: formData });
  };
  const handelOfficephotoRemove = (id: number) => {
    const restPhoto = organizationOfficePhoto.filter(
      (_img: string, index: number) => index !== id
    );
    setOrganizationOfficePhoto(restPhoto);
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <FormControl sx={{ width: '100%' }} error={error.ownershipStatus}>
              <InputLabel id='demo-simple-select-label'>
                Select ownership status
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='ownership_status'
                label='Select ownership status'
                sx={{ borderRadius: '10px' }}
                value={ownershipStatus}
                required
                onChange={(e: any) => {
                  setOwnershipStatus(e.target.value);
                  setError({
                    ...error,
                    ownershipStatus: false,
                  });
                }}
              >
                <MenuItem value={'Proprietorship'}>Proprietorship</MenuItem>
                <MenuItem value={'Partnership'}>Partnership</MenuItem>
                <MenuItem value={'Incorporator'}>Incorporator</MenuItem>
              </Select>
            </FormControl>

            {error.ownershipStatus && (
              <FormHelperText error>
                Ownership status is required
              </FormHelperText>
            )}
          </Box>
          {ownershipStatus === 'Partnership' && (
            <Box mt={2}>
              <FileValidation
                mt={'0'}
                isRequired
                register={register}
                clearErrors={clearErrors}
                setError={formError}
                errors={errors}
                label='Upload photocopy of deed of partnership ( max size 5mb )'
                fieldName={`company_partnership_deed_file`}
              />
            </Box>
          )}
          {ownershipStatus === 'Incorporator' && (
            <Box mt={2}>
              <FileValidation
                mt={'0'}
                isRequired
                register={register}
                clearErrors={clearErrors}
                setError={formError}
                errors={errors}
                label='Upload incorporation certificate ( max size 5mb )'
                fieldName={`company_incorporation_certificate`}
              />
            </Box>
          )}
          {ownershipStatus === 'Incorporator' && (
            <Box mt={2}>
              <FileValidation
                mt={'0'}
                isRequired
                register={register}
                clearErrors={clearErrors}
                setError={formError}
                errors={errors}
                label=' Upload memorandum document ( max size 5mb )'
                fieldName={`company_memorandum_file`}
              />
            </Box>
          )}
          {/* office agreement */}
          <Box mt={2}>
            <FormControl sx={{ width: '100%' }} error={error.officeAgreement}>
              <InputLabel id='demo-simple-select-label'>
                Select office agreement
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label=' Select office agreement'
                sx={{ borderRadius: '10px' }}
                value={officeAgreement}
                required
                onChange={(e: any) => {
                  setOfficeAgreement(e.target.value);
                  setError({
                    ...error,
                    officeAgreement: false,
                  });
                }}
              >
                <MenuItem value={'Self premise'}>Self premise</MenuItem>
                <MenuItem value={'Rented premise'}>Rented premise</MenuItem>
              </Select>
            </FormControl>
            {error.officeAgreement && (
              <FormHelperText error>
                OfficeAgreement status is required
              </FormHelperText>
            )}
          </Box>

          {officeAgreement && (
            <Box mt={2}>
              <FileValidation
                mt={'0'}
                isRequired
                register={register}
                clearErrors={clearErrors}
                setError={formError}
                errors={errors}
                label={
                  officeAgreement === 'self premise'
                    ? 'Upload deed of agreement of self premise office space ( max size 5mb )'
                    : 'Upload deed of agreement of rented premise office space ( max size 5mb )'
                }
                fieldName={`company_agreement_file`}
              />
            </Box>
          )}
          <Box mt={2}>
            <FileValidation
              mt={'0'}
              isRequired
              register={register}
              clearErrors={clearErrors}
              setError={formError}
              errors={errors}
              label='Upload 03 ( Three months) latest bank statement ( max size 5mb )'
              fieldName={`company_bank_statement`}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <FileValidation
              mt={'0'}
              isRequired
              register={register}
              clearErrors={clearErrors}
              setError={formError}
              errors={errors}
              label='Upload staff list on office pad with seal and signature of owner (
              max size 5mb )'
              fieldName={`company_staff_list_file`}
            />
          </Box>
          <Box mt={2}>
            <FileValidation
              mt={'0'}
              isRequired
              register={register}
              clearErrors={clearErrors}
              setError={formError}
              errors={errors}
              label=' Upload one staff any GDS (Sabre/Galileo?Amadeus) certificate ( max
              size 5mb )'
              fieldName={`company_one_staff_certificate`}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {organizationOfficePhoto?.map(
                (singleImg: string | Blob, index: number) => {
                  return (
                    <Box sx={{ position: 'relative' }} key={index}>
                      <img
                        src={URL?.createObjectURL(singleImg as Blob)}
                        alt=''
                        width={100}
                        height={100}
                      />
                      <HighlightOffIcon
                        onClick={() => handelOfficephotoRemove(index)}
                        sx={{
                          position: 'absolute',
                          top: -4,
                          right: -4,
                          cursor: 'pointer',
                        }}
                      />
                    </Box>
                  );
                }
              )}
            </Box>

            <Box>
              <label htmlFor=''>
                Upload internal &#38; external photographs of office ( max size
                5mb )<span style={{ color: 'red' }}>*</span>
              </label>
              <TextField
                id='outlined-basic'
                variant='outlined'
                type='file'
                inputProps={{ accept: 'image/jpeg,image/png' }}
                required
                fullWidth
                onChange={(e: any) =>
                  setOrganizationOfficePhoto([
                    ...organizationOfficePhoto,
                    e.target.files[0],
                  ])
                }
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FileValidation
              mt={'0'}
              isRequired
              register={register}
              clearErrors={clearErrors}
              setError={formError}
              errors={errors}
              label='Upload 03 ( Three months) latest sales statement ( max size 5mb )'
              fieldName={`company_sales_statement`}
            />
          </Box>
        </Grid>
      </Grid>
      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button disabled variant='contained' disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type='submit' variant='contained' disableElevation>
              Save
            </Button>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AddCompanyDocument;
