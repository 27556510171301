import { Box, Grid, Typography } from '@mui/material';
import { IAdmissionDATA } from '../../../rest/types';
import moment from 'moment';
import { imgUrl } from '../../../Helpers/utils/Constant';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
function SecondPageForm({ admissionData }: { admissionData: IAdmissionDATA }) {
  return (
    <Box mt={10}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>9. Number and Date of Latest Trade License</Typography>
            <table className='member-info-print'>
              <tbody>
                <tr>
                  <td>{admissionData.company_trade_license}</td>
                  <th className='table-middle-head'>Date</th>
                  <td>
                    {moment(
                      admissionData.company_trade_license_expiry_date
                    ).format('DD/MM/YYYY')}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={6}>
            <Typography>10. TIN Number</Typography>
            <table className='member-info-print'>
              <tbody>
                <tr>
                  <td style={{ height: '20px' }}>
                    {admissionData.company_tin_number}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2} display={'flex'} alignItems={'center'}>
          <Grid item xs={6}>
            <Typography>11. Other Business License</Typography>
            <table className='member-info-print'>
              <thead>
                <tr className='business-license-table-head'>
                  <th>{admissionData.hajj_license === 1 ? 'Yes' : 'No'}</th>
                  <th>{admissionData.umrah_license === 1 ? 'Yes' : 'No'} </th>
                  <th>
                    {admissionData.recruting_license === 1 ? 'Yes' : 'No'}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='business-license-table-body'>
                  <td>Hajj</td>
                  <td>Umrah</td>
                  <td>Recruiting</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={6} mt={2.4}>
            <table className='member-info-print'>
              <thead>
                <tr>
                  <th colSpan={100}>Involved IN Tourism Activities</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{admissionData.inbound === 1 ? 'Yes' : 'No'}</td>
                  <th className='table-middle-head'>Inbound</th>
                  <td className='table-middle-head'>
                    {admissionData.outbound === 1 ? 'Yes' : 'No'}
                  </td>
                  <th>Outbound</th>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Typography>12. Other Association membership</Typography>
        <table className='member-info-print'>
          <thead>
            <tr className='business-license-table-head'>
              <th>{admissionData.baira_membership === 1 ? 'Yes' : 'No'}</th>
              <th>{admissionData.haab_membership === 1 ? 'Yes' : 'No'}</th>
              <th>{admissionData.iata_membership === 1 ? 'Yes' : 'No'}</th>
              <th>{admissionData.toab_membership === 1 ? 'Yes' : 'No'} </th>
              <th>{admissionData.tdab_membership === 1 ? 'Yes' : 'No'}</th>
            </tr>
          </thead>
          <tbody>
            <tr className='business-license-table-body'>
              <td>BAIRA</td>
              <td>HAAB</td>
              <td>IATA</td>
              <td>TOAB</td>
              <td>TDAB</td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box mt={2}>
        <Typography>
          13. NO & Date of Registration / Renewal Certificate from Ministry of
          Civil Aviation & Tourism
        </Typography>
        <table className='member-info-print'>
          <tbody>
            <tr>
              <td>{admissionData.certificate_number}</td>
              <th className='table-middle-head'>Date</th>
              <td>
                {moment(admissionData.certificate_expiry_date).format(
                  'DD/MM/YYYY'
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box mt={2}>
        <Typography>14. Payment Information</Typography>
        <Grid container>
          <Grid item xs={6}>
            {' '}
            <table className='member-info-print'>
              <tbody>
                <tr>
                  <th className='table-middle-head'>Account No</th>
                  <td className='table-middle-head'>
                    {admissionData?.payments?.bank_ac_no}
                  </td>
                </tr>
                <tr>
                  <th className='table-middle-head'>Deposit Date</th>
                  <td className='table-middle-head'>
                    {moment(admissionData?.payments?.deposit_date).format(
                      'DD-MMM-YYYY'
                    )}
                  </td>
                </tr>
                <tr>
                  <th className='table-middle-head'>Bank Name</th>
                  <td className='table-middle-head'>
                    {admissionData?.payments?.bank_name}
                  </td>
                </tr>

                <tr>
                  <th className='table-middle-head'>Branch Name</th>
                  <td className='table-middle-head'>
                    {admissionData?.payments?.branch_name}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={6}>
            {' '}
            <table className='member-info-print'>
              <tbody>
                <tr>
                  <th className='table-middle-head'>Amount</th>
                  <td className='table-middle-head'>
                    ৳ {admissionData?.payments?.amount}
                  </td>
                </tr>
                <tr>
                  <th className='table-middle-head'>
                    Upload Bank Slip/Document
                  </th>
                  <td className='table-middle-head'>
                    {admissionData?.payments?.bank_slip ? 'Yes' : 'No'}
                  </td>
                </tr>
                <tr>
                  <th className='table-middle-head'>Reference No</th>
                  <td className='table-middle-head'>
                    {admissionData?.payments?.reference_no}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="I do hereby declare that all the information in this application is true and correct. I am conversant with the ATAB's Memorandum of Association and Articles of Association, with Code of Conduct. Any violation is resulted to cancellation of membership."
        />
      </Box>
      <Box mt={2}>
        <table className='member-info-print'>
          <thead>
            <tr className='business-license-table-head'>
              <th>
                Proposer no-1 with(ATAB member )
                <br /> Name,Seal and Signature
              </th>
              <th>
                {' '}
                Proposer no-2 with(ATAB member )
                <br /> Name,Seal and Signature{' '}
              </th>
              <th> Applicant seal & Signature</th>
            </tr>
          </thead>
          <tbody>
            <tr className='business-license-table-body business-license-signature'>
              <td>
                {admissionData?.proposers?.length ? (
                  <img
                    src={`${imgUrl}/${admissionData?.proposers[0]?.proposer_seal_signature}`}
                    alt=''
                    loading='lazy'
                    width={200}
                    height={150}
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  ''
                )}
              </td>
              <td>
                {admissionData?.proposers?.length ? (
                  <img
                    src={`${imgUrl}/${admissionData?.proposers[1]?.proposer_seal_signature}`}
                    alt=''
                    loading='lazy'
                    width={200}
                    height={150}
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  ''
                )}
              </td>
              <td>
                {admissionData.company_owner_signature ? (
                  <img
                    src={`${imgUrl}/${admissionData.company_owner_signature}`}
                    alt=''
                    loading='lazy'
                    width={200}
                    height={150}
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default SecondPageForm;
