import { useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyOwnerList } from '../../../rest/types';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateCompanyOwnerMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import { useState } from 'react';
import FileValidation from '../../../Components/FileValidation/FileValidation';
interface IProps {
  singleOwner: ICompanyOwnerList;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdateCompanyOwner = ({ singleOwner, setToggle }: IProps) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const [representative, setRepresentative] = useState<number>(
    singleOwner?.company_representative
  );
  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyOwnerMutation(data, singleOwner.company_owner_id);
    },
    onSuccess: () => {
      showToast('successfully updated', 'success');
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_DETAILS],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    inputsData.company_owner_id = singleOwner?.company_owner_id;
    const formData = new FormData();

    formData.append('company_representative', String(representative));
    Object.keys(inputsData).forEach((key) => {
      if (inputsData[key]) {
        if (typeof inputsData[key] === 'object') {
          if (inputsData[key][0]) {
            formData.append(key, inputsData[key][0]);
          }
        } else {
          formData.append(key, inputsData[key]);
        }
      }
    });
    mutation.mutate(formData);
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box sx={{ position: 'relative' }}>
        <Grid container spacing={2} mt={0.5}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                label='Owner name'
                type='text'
                defaultValue={singleOwner?.company_owner_name}
                fullWidth
                {...register('company_owner_name')}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                label='Owner designation'
                type='text'
                defaultValue={singleOwner?.company_owner_designation}
                fullWidth
                {...register('company_owner_designation')}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <FileValidation
                mt='0'
                hideCustomLabel
                register={register}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                label='Owner NID (both sides) (max size 5MB)'
                fieldName='company_owner_nid_file'
              />
            </Box>
            <Box>
              <FileValidation
                mt='0'
                hideCustomLabel
                register={register}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                label='Owner seal & signature (max size 5MB)'
                fieldName='company_owner_signature'
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='date'
                type='text'
                label='Owner phone'
                defaultValue={singleOwner?.company_owner_phone}
                fullWidth
                {...register('company_owner_phone')}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                label='Owner email'
                type='email'
                defaultValue={singleOwner?.company_owner_email}
                fullWidth
                {...register('company_owner_email')}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <FileValidation
                mt='0'
                hideCustomLabel
                register={register}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                label='Owner bio data (max size 5MB)'
                fieldName='company_owner_biodata'
              />
            </Box>
            <Box>
              <FileValidation
                mt='0'
                hideCustomLabel
                register={register}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                label='Owner photo (max size 5MB)'
                fieldName='company_owner_photo'
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                type='text'
                label='Owner address'
                defaultValue={singleOwner?.company_owner_address}
                fullWidth
                {...register('company_owner_address')}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id='outlined-basic'
                variant='outlined'
                label='Owner NID'
                type='number'
                defaultValue={singleOwner?.company_owner_nid}
                fullWidth
                {...register('company_owner_nid')}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <FileValidation
                mt='0'
                hideCustomLabel
                register={register}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                label='Owner passport (max size 5MB)'
                fieldName='company_owner_passport'
              />
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Represent organization
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={representative}
                  label='Represent organization'
                  onChange={(e) => setRepresentative(Number(e.target.value))}
                >
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ position: 'absolute', top: -20, right: -30 }}>
          <Tooltip title='Update' sx={{ mr: 2 }}>
            <IconButton onClick={() => setToggle((prv) => !prv)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ textAlign: 'end', mt: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyOwner;
