import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  IInvoiceItem,
  IModalTypes,
  IResponseSingleInvoice,
  ISingleInvoice,
} from '../type/PaymentsTypes';
import { HttpClient } from '../../../rest/utils/http';
import { getStatusLabel } from '../../../Utils/Comibined';

const style = {
  position: 'absolute',
  top: { xs: '40%', md: '50%', xl: '40%' },
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '100%', md: '50%' },
  borderRadius: '5 bpx',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const PaymentDetailsModal = ({
  open,
  handleClose,
  inVoiceId,
  user,
}: IModalTypes) => {
  const [singleInvoice, setSingleInvoice] = useState<ISingleInvoice>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (inVoiceId) {
      try {
        (async function () {
          const res: IResponseSingleInvoice = await HttpClient.get(
            `/api/atab/member/invoice/${inVoiceId}`
          );
          if (res.success) {
            setSingleInvoice(res.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })();
      } catch (error) {
        setLoading(false);
      }
    }
  }, [inVoiceId]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'center', pb: 3 }}>
            <Typography id='modal-modal-title' variant='h3'>
              Invoice details
            </Typography>
          </Box>

          {loading ? (
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box>
                {singleInvoice?.invoiceItems?.length && (
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            {/* <TableCell align="center">Check No</TableCell> */}
                            <TableCell align='center'>Paid for</TableCell>
                            <TableCell align='center'>Year</TableCell>
                            <TableCell align='center'>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {singleInvoice?.invoiceItems?.map(
                            (sItem: IInvoiceItem, index: number) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align='center'>
                                    {sItem.atab_payment_invoice_item_name}
                                  </TableCell>
                                  <TableCell align='center'>
                                    {sItem.atab_payment_invoice_item_year}
                                  </TableCell>

                                  <TableCell align='center'>
                                    {sItem.atab_payment_invoice_item_amount}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
              <Box className='company-info' pt={3}>
                <ul>
                  <li>
                    <span>Status</span>:
                    <p style={{ marginLeft: '10px' }}>
                      {getStatusLabel(
                        Number(singleInvoice?.atab_payment_invoice_due) > 0
                          ? 'unpaid'
                          : 'paid'
                      )}
                    </p>
                  </li>
                  <li>
                    <span>Invoice No.</span>:
                    <p>{singleInvoice?.atab_payment_invoice_no}</p>
                  </li>
                  <li>
                    <span>Total amount</span>:
                    <p>{singleInvoice?.atab_payment_invoice_total_amount} tk</p>
                  </li>
                  <li>
                    <span>Due amount</span>:
                    <p>{singleInvoice?.atab_payment_invoice_due} tk</p>
                  </li>

                  <li>
                    <span>Email</span>:
                    <p>{singleInvoice?.user_member_email} </p>
                  </li>
                  <li>
                    <span>Date</span>:
                    <p>
                      {moment(
                        singleInvoice?.atab_payment_invoice_issue_date
                      ).format('Do MMM YYYY')}
                    </p>
                  </li>

                  <li>
                    <span>Phone number </span>:
                    <p>{singleInvoice?.user_member_phone} </p>
                  </li>
                </ul>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentDetailsModal;
