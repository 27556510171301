import { useState } from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'; // Import the EditIcon
import { useAuthContext } from '../../../../../Context/AuthContext/AuthContext';
import UpdateOfflinePaymentInfo from './UpdateOfflinePaymentInfo'; // Import necessary components
import { useCompanyDetailsQuery } from '../../../../../rest/useCompanyInfo';
import OfflinePaymentCardInformation from './OfflinePaymentCardInformation';
import PaymentOfflineInfromation from './PaymentOfflineInfromation';

const OfflinePaymentInformation = ({
  setPaymentOfflineInformation,
}: {
  setPaymentOfflineInformation: React.Dispatch<React.SetStateAction<null>>;
}) => {
  const { user } = useAuthContext();
  const { companyData, isLoading } = useCompanyDetailsQuery();
  const [toggle, setToggle] = useState(false);
  return (
    <Box>
      <PaymentOfflineInfromation
        setPaymentOfflineInformation={setPaymentOfflineInformation}
      />
      {/* <Box display={'flex'} justifyContent={'end'}>
        {!user?.is_applied && companyData?.member_company_name && (
          <Tooltip title='Update' sx={{ mr: 2 }}>
            <IconButton onClick={() => setToggle(!toggle)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      {toggle ? (
        <UpdateOfflinePaymentInfo />
      ) : (
        <>
          {companyData?.member_company_name ? (
            <OfflinePaymentCardInformation
              loading={isLoading}
              data={companyData}
            />
          ) : (
            <PaymentOfflineInfromation />
          )}
        </>
      )} */}
    </Box>
  );
};

export default OfflinePaymentInformation;
