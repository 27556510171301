import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDuplicateSingleQuery } from '../../rest/useDuplicateCertificate';
import { getStatusLabel } from '../../Utils/Label/status-label';
import PageLoader from '../../Components/Spinner/PageLoader';
import ModalsWrapper from '../../Components/Modals/ModalsWrapper';
import PdfAndImageView from '../../Components/pdfAndImageView/PdfAndImageView';
import { CommonTypoGraphy } from '../../Components/pdfAndImageView/CommonTypoGraphy';
import OfflinePaymentCardInformation from '../new-member-applications/IndexFile/paymentInformation/offline/OfflinePaymentCardInformation';

const SingleDuplicateCertificateApplication = () => {
  const { id } = useParams();
  const { data, fetchData, loading } = useDuplicateSingleQuery(
    id !== undefined ? id : ''
  );
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading ? (
        <Container sx={{ pt: 5 }}>
          <Card>
            <CardContent>
              {data ? (
                <Grid
                  container
                  pt={2}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application ID:
                    </Typography>
                    {data.duplicate_certificate_application_id}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application Date:
                    </Typography>
                    {moment(
                      data.duplicate_certificate_application_created_at
                    ).format('MMM Do YY')}
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Company name:
                    </Typography>
                    {data.user_member_company_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant='body1'
                      fontWeight='bold'
                      color='text.primary'
                      display='inline'
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {getStatusLabel(
                      data.duplicate_certificate_application_status!
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    {' '}
                    <CommonTypoGraphy
                      name='View Civil Aviation'
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          data?.duplicate_certificate_application_civil_aviation
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name='View Trade License'
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          data?.duplicate_certificate_application_trade_license
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name='View GD Copy'
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          data?.duplicate_certificate_application_gd_copy
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name='View Application'
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          data?.duplicate_certificate_application_application_file
                        );
                      }}
                    />
                  </Grid>
                  {data.duplicate_certificate_application_rejeceted_reason ? (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Note:
                      </Typography>
                      {data.duplicate_certificate_application_rejeceted_reason}
                    </Grid>
                  ) : (
                    ''
                  )}

                  {data?.paymentInfo && (
                    <Grid item xs={12}>
                      <Divider>
                        <Chip label='Payment Information' size='small' />
                      </Divider>

                      <OfflinePaymentCardInformation
                        loading={loading}
                        data={data?.paymentInfo}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  color='text.primary'
                  display='inline'
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  No Data found
                </Typography>
              )}
            </CardContent>
          </Card>
        </Container>
      ) : (
        <PageLoader />
      )}
      <>
        <ModalsWrapper
          modalData={{ title: 'View document' }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default SingleDuplicateCertificateApplication;
