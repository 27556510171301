import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Grid from '@mui/material/Grid';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import PrintInformationForm from './utils/PrintInformationForm';
import { UPDATE_AUTH_USER_INFO } from '../../Helpers/utils/Constant';
import CompanyInformationIndex from './IndexFile/CompanyInformationIndex';
import TradeLicenseIndex from './IndexFile/TradeLicenseIndex';
import CivilAviationIndex from './IndexFile/CivilAviationIndex';
import CompanyDocumentIndex from './IndexFile/CompanyDocumentIndex';
import ProposerIndex from './IndexFile/ProposerIndex';
import OtherBusinessInfoIndex from './IndexFile/OtherBusinessInfoIndex';
import BusinessLicenseInfoIndex from './IndexFile/BusinessLicenseInfoIndex';
import CompanyOwnerIndex from './IndexFile/CompanyOwnerIndex';
import { showToast } from '../../Toaster/Toaster';
import { useMutation, useQueryClient } from 'react-query';
import { PostApplicationSubmit } from './api/newMemberApplicationEndPoint';
import { API_ENDPOINTS } from '../../rest/utils/api-endpoints';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PaymentInformation from './IndexFile/paymentInformation/PaymentInformation';

function NewMemberApplications() {
  const [paymentOfflineInformation, setPaymentOfflineInformation] =
    useState(null);
  const componentRef = useRef();
  const { user, dispatch } = useAuthContext();
  const [check, setCheck] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
    documentTitle: `ATAB_${user.user_member_company_name}_${user.user_member_id}`,
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (body: any) => {
      return PostApplicationSubmit(body);
    },
    onSuccess: (data: any) => {
      if (data?.data?.redirect_url) {
        window.open(data?.data?.redirect_url, '_blank', 'noopener,noreferrer');
      }
      showToast(`Member application submit successfully`, 'success');
      dispatch?.({
        type: UPDATE_AUTH_USER_INFO,
        payload: {
          is_applied: 1,
        },
      });
      setPaymentOfflineInformation(null);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.APPLICATION_APPLIED],
      });
    },
  });

  const handelSubmit = () => {
    const formData = new FormData();
    if (paymentOfflineInformation === 'online_payment') {
      formData.append('type', 'online');
      formData.append('success_page', 'http://localhost:3002/payment-success');
      formData.append('failed_page', 'http://localhost:3002/payment-failed');
      formData.append('cancelled_page', 'http://localhost:3002/payment-reject');
    } else {
      if (paymentOfflineInformation) {
        Object.keys(paymentOfflineInformation)?.forEach((key) => {
          if (key === 'bank_slip' && paymentOfflineInformation[key]) {
            formData.append(key, paymentOfflineInformation[key][0]);
          } else {
            formData.append(key, paymentOfflineInformation[key]);
          }
        });
      }
    }

    mutation.mutate(formData);
  };
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{ pt: 5 }}
    >
      <Grid item xs={12}>
        <Card sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component='div' variant='h3'>
                {user?.is_applied ? 'View' : 'Update'} your information
              </Typography>
            </CardContent>
          </Box>

          {/* {user.is_applied ? ( */}
          <IconButton
            onClick={handlePrint}
            title='Print admission from'
            sx={{ m: 2 }}
            aria-label=''
          >
            <PrintIcon sx={{ height: 24, width: 24 }} />
          </IconButton>
          {/* ) : (
            ""
          )} */}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CompanyInformationIndex isApplied={user?.is_applied} />
      </Grid>
      <Grid item xs={12}>
        <CompanyOwnerIndex isApplied={user?.is_applied} />
      </Grid>{' '}
      {/* <Grid item xs={12}>
        <RepresentativeIndex />
      </Grid> */}
      <Grid item xs={12}>
        <CompanyDocumentIndex />
      </Grid>
      {/* Company trade license information */}
      <Grid item xs={12}>
        <TradeLicenseIndex />
      </Grid>
      {/* Select business license information */}
      <Grid item xs={12}>
        <BusinessLicenseInfoIndex />
      </Grid>
      {/* Select other business information */}
      <Grid item xs={12}>
        <OtherBusinessInfoIndex isApplied={user?.is_applied} />
      </Grid>
      {/* civil aviation information */}
      <Grid item xs={12}>
        <CivilAviationIndex isApplied={user?.is_applied} />
      </Grid>
      {/* payment information */}
      {/* Company proposer */}
      <Grid item xs={12} sx={{ pb: 2 }}>
        <ProposerIndex />
      </Grid>
      <Grid item xs={12}>
        <PaymentInformation
          setPaymentOfflineInformation={setPaymentOfflineInformation}
        />
      </Grid>
      {!user.is_applied && (
        <Grid item xs={12} sx={{ pb: 1 }}>
          <Box>
            {' '}
            <FormControlLabel
              control={
                <Checkbox
                  onClick={(e: any) => {
                    setCheck(e.target.checked);
                    scrollToBottom();
                  }}
                />
              }
              label="I do hereby declare that all the information in this application is true and correct. I am conversant with the ATAB's Memorandum of Association and Articles of Association, with Code of Conduct. Any violation is resulted to cancellation of membership."
            />
          </Box>
        </Grid>
      )}
      {check ? (
        <>
          {' '}
          {!user.is_applied && (
            <Grid item xs={12} ref={bottomRef}>
              <Box sx={{ width: '100%', textAlign: 'center', pb: 5 }}>
                {mutation.isLoading ? (
                  <Button variant='contained' sx={{ width: '25%', mt: 1 }}>
                    loading...
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    sx={{ width: '25%' }}
                    onClick={handelSubmit}
                  >
                    Submit your application
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </>
      ) : (
        ''
      )}
      <Box display={'none'}>
        <PrintInformationForm componentRef={componentRef} />
      </Box>
    </Grid>
  );
}

export default NewMemberApplications;
