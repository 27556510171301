import { Box, Container } from '@mui/material';
import FirstPageForm from '../components/FirstPageForm';
import SecondPageForm from '../components/SecondPageForm';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useAdmission } from '../../../rest/useCompanyInfo';
import ThirdPageForm from '../components/ThirdPageForm';

function PrintInformationForm({ componentRef }: any) {
  const { user } = useAuthContext();
  const { admissionData } = useAdmission(String(user.user_member_id));

  return (
    <div ref={componentRef}>
      <Container maxWidth='md'>
        <FirstPageForm admissionData={admissionData} />
        <Box my={15}>
          <SecondPageForm admissionData={admissionData} />
        </Box>
        <Box mt={90}>
          <ThirdPageForm memberId={user.user_member_id} />
        </Box>
      </Container>
    </div>
  );
}

export default PrintInformationForm;
