import { Box, Grid, FormControlLabel, Button, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { IAssociationMember } from '../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCompanyAssociationUpdate } from '../../../rest/useCompanyInfo';
import FileValidation from '../../../Components/FileValidation/FileValidation';

const AddOtherBusinessLicense = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyAssociationUpdate();
  const [associationMember, setAssociationMember] =
    useState<IAssociationMember>({
      baira_membership: 0,
      haab_membership: 0,
      iata_membership: 0,
      toab_membership: 0,
      tdab_membership: 0,
    });

  const onSubmit = (inputsData: any) => {
    inputsData = { ...inputsData, ...associationMember };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutate({ input: formData });
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Grid container spacing={2} pl={5}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='BAIRA'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      baira_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      baira_membership: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='HAAB'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      haab_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      haab_membership: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {associationMember.baira_membership ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload association BAIRA document ( max size 5mb )'
                  fieldName={`baira_membership_file`}
                />
              </Box>
            ) : (
              ''
            )}
            {associationMember.haab_membership ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload association HAAB document ( max size 5mb )'
                  fieldName={`haab_membership_file`}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='IATA'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      iata_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      iata_membership: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label='TOAB'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      toab_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      toab_membership: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {associationMember.iata_membership ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload association IATA document ( max size 5mb )'
                  fieldName={`iata_membership_file`}
                />
              </Box>
            ) : (
              ''
            )}
            {associationMember.toab_membership ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload association TOAB document ( max size 5mb )'
                  fieldName={`toab_membership_file`}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ pl: { xs: 12, md: 0 } }}>
            <FormControlLabel
              control={<Checkbox />}
              label='TDAB'
              onClick={(e: any) => {
                if (e.target.checked) {
                  setAssociationMember({
                    ...associationMember,
                    tdab_membership: 1,
                  });
                } else {
                  setAssociationMember({
                    ...associationMember,
                    tdab_membership: 0,
                  });
                }
              }}
            />
          </Box>
          <Box>
            {associationMember.tdab_membership ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload association TDAB document ( max size 5mb )'
                  fieldName={`tdab_membership_file`}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
      </Grid>
      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
          {isLoading ? (
            <Button disabled variant='contained' disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type='submit' variant='contained' disableElevation>
              Save
            </Button>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AddOtherBusinessLicense;
