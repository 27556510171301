import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  Tooltip,
  Divider,
  Chip,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '../../Context/AuthContext/AuthContext';
import { useDuplicateCertificateMutation } from '../../rest/useDuplicateCertificate';
import CommonOfflinePaymentInfo from '../new-member-applications/IndexFile/paymentInformation/offline/CommonOfflinePaymentInfo';

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: () => void;
};

const CreateDuplicateApplication = ({ handelClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      bank_ac_no: '00890320000141',
      amount: '1000',
      type: 'offline',
      bank_name: 'Trust Bank',
    },
  });
  const { createData, data, loading } = useDuplicateCertificateMutation();
  useEffect(() => {
    if (data.success) {
      handelClose(false);
      setReload();
    }
  }, [data.success, setReload, handelClose]);
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object') {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    formData.append(
      'duplicate_certificate_application_member_id',
      user.user_member_id?.toString()
    );
    createData(formData);
  };

  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={'sm'}>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label='Duplicate Certificate Copy ( max size 5mb )'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_gd_copy')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label='Duplicate Certificate Application file ( max size 5mb )'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                'duplicate_certificate_application_application_file'
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label={
                <Tooltip title='Duplicate Certificate Proof of Submission of Tax Return (Acknowledgement Receipts) file ( max size 5mb )'>
                  <span style={{ cursor: 'pointer' }}>
                    Duplicate Certificate Proof of Submission of Tax Return
                    (Acknowledgement Receipts) file ( max size 5mb )
                  </span>
                </Tooltip>
              }
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_tax_return')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label='Duplicate Certificate trade license file ( max size 5mb )'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_trade_license')}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label='Duplicate Certificate civil aviation file ( max size 5mb )'
              required
              id='outlined-required'
              type='file'
              InputLabelProps={{
                shrink: true,
              }}
              {...register('duplicate_certificate_application_civil_aviation')}
            />
          </Grid>

          <Grid xs={12} item sx={{ my: 2 }}>
            <Divider>
              <Chip label='Offline Payment Information' size='small' />
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <CommonOfflinePaymentInfo
              clearErrors={clearErrors}
              setError={setError}
              errors={errors}
              register={register}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!loading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator='Loading...'
              variant='contained'
              fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateDuplicateApplication;
