import { Box, Grid, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { useCompanyLicenseUpdate } from '../../../rest/useCompanyInfo';
import FileValidation from '../../../Components/FileValidation/FileValidation';

const AddTradeLicenseInfo = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyLicenseUpdate();

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    mutate({ input: formData });
  };
  return (
    <>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <label htmlFor=''>
                  Trade license number <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  placeholder='Enter trade license number '
                  type='text'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('company_trade_license', {
                    required: ' Trade license number is required',
                  })}
                  error={
                    errors.company_trade_license &&
                    Boolean(errors.company_trade_license)
                  }
                  helperText={
                    Boolean(errors?.company_trade_license?.message)
                      ? `${errors?.company_trade_license?.message}`
                      : ''
                  }
                />
              </Box>
              <Box>
                <label htmlFor=''>
                  TIN number <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  placeholder='Enter tin number'
                  type='number'
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('company_tin_number', {
                    required: 'TIN number is required',
                  })}
                  error={
                    errors.company_tin_number &&
                    Boolean(errors.company_tin_number)
                  }
                  helperText={
                    Boolean(errors?.company_tin_number?.message)
                      ? `${errors?.company_tin_number?.message}`
                      : ''
                  }
                />
              </Box>
              <Box>
                <FileValidation
                  mt={'0'}
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload BIN File / document ( max size 5mb )'
                  fieldName={`company_bin_file`}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box>
                <label htmlFor=''>
                  Trade license expiry date{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <TextField
                  id='date'
                  type='date'
                  required
                  {...register('company_trade_license_expiry_date', {
                    required: ' Trade license expiry date is required',
                  })}
                  error={
                    errors.company_trade_license_expiry_date &&
                    Boolean(errors.company_trade_license_expiry_date)
                  }
                  helperText={
                    Boolean(errors?.company_trade_license_expiry_date?.message)
                      ? `${errors?.company_trade_license_expiry_date?.message}`
                      : ''
                  }
                  // value={date}
                  // onChange={(e) => setDate(e.target.value)}
                  sx={{ width: '100%', pb: 2 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box>
                <FileValidation
                  isRequired
                  mt={'0'}
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Proof of Submission of Tax Return (Acknowledgement Receipt) ( max 5mb )'
                  fieldName={`company_tin_file`}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ mb: 2 }}>
                <FileValidation
                  mt={'0'}
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Upload trade license File / document ( max size 5mb )'
                  fieldName={`company_trade_license_file`}
                />
              </Box>
              <Box>
                <label htmlFor=''>
                  Bin number (Business Identification Number)
                </label>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  placeholder='Enter bin number'
                  type='text'
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register('company_bin_number')}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!user.is_applied ? (
          <Box sx={{ mt: 2, textAlign: 'end', mb: 1 }}>
            {isLoading ? (
              <Button disabled variant='contained' disableElevation>
                Loading...
              </Button>
            ) : (
              <Button type='submit' variant='contained' disableElevation>
                Save
              </Button>
            )}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  );
};

export default AddTradeLicenseInfo;
