import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSingleOwnershipChangeQuery } from '../../rest/useChangeOwnership ';
import PageLoader from '../../Components/Spinner/PageLoader';
import { getStatusLabel } from '../../Utils/Label/status-label';
import { imgUrl } from '../../Helpers/utils/Constant';
import ModalsWrapper from '../../Components/Modals/ModalsWrapper';
import PdfAndImageView from '../../Components/pdfAndImageView/PdfAndImageView';
import { CommonTypoGraphy } from '../../Components/pdfAndImageView/CommonTypoGraphy';

const SingleOwnershipChangeApplication = () => {
  const { id } = useParams();
  const {
    data: application,
    fetchData,
    loading,
  } = useSingleOwnershipChangeQuery(id !== undefined ? id : '');
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>('');
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading ? (
        <Card sx={{ mx: 2, mt: 5 }}>
          <CardContent>
            {application ? (
              <Grid
                container
                pt={2}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Application ID:
                  </Typography>
                  {application.ownership_change_application_id}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Application Date:
                  </Typography>
                  {moment(
                    application.ownership_change_application_created_at
                  ).format('MMM Do YY')}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Company name:
                  </Typography>
                  {application.user_member_company_name}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Status:
                  </Typography>
                  {getStatusLabel(
                    application.ownership_change_application_status
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {' '}
                  <CommonTypoGraphy
                    name='View MOCAT Approval Copy'
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        application?.ownership_change_application_mocat_approval_copy_file
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View MOCAT Certificate English Copy'
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        application?.ownership_change_application_mocat_approval_copy_file
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {' '}
                  <CommonTypoGraphy
                    name='View Trade License'
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        application?.ownership_change_application_trade_license
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {' '}
                  <CommonTypoGraphy
                    name='Proof of Submission of Tax Return (Acknowledgement Receipts) (max size 5mb)'
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        application?.ownership_change_application_tax_certificate
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View Forwarding Letter'
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        application?.ownership_change_application_forwarding_letter
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <CommonTypoGraphy
                    name='View Ownership Change Deed'
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        application?.ownership_change_application_ownership_change_deed
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    color='text.primary'
                    display='inline'
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Note:
                  </Typography>
                  {application.ownership_change_application_rejected_reason}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title='Previous owner' />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Name:
                          </Typography>
                          {application.prev_company_owner_name}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Designation:
                          </Typography>
                          {application.prev_company_owner_designation}
                        </Grid>
                        {/* this prev owner  */}
                        {application.prev_company_owner_photo && (
                          <Grid item xs={6}>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                              align='center'
                            >
                              <Avatar
                                variant='rounded'
                                alt='Previous Owner Photo'
                                src={`${imgUrl}/${application.prev_company_owner_photo}`}
                                sx={{ width: 84, height: 84 }}
                              />
                              <a
                                href={`${imgUrl}/${application.prev_company_owner_photo}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Download Previous Owner Photo
                              </a>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title='New owner' />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Name:
                          </Typography>
                          {application.company_owner_name}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Phone:
                          </Typography>
                          {application.company_owner_phone}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Designation:
                          </Typography>
                          {application.company_owner_designation}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Email:
                          </Typography>
                          {application.company_owner_email}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Address:
                          </Typography>
                          {application.company_owner_address}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            NID:
                          </Typography>
                          {application.company_owner_nid}
                        </Grid>
                        <Grid item xs={6}>
                          <CommonTypoGraphy
                            name='View NID'
                            onClick={() => {
                              setOpen(true);
                              setImageFile(application?.company_owner_nid_file);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CommonTypoGraphy
                            name='View New Owner Photo'
                            onClick={() => {
                              setOpen(true);
                              setImageFile(application?.company_owner_photo);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {' '}
                          <CommonTypoGraphy
                            name='View Biodata'
                            onClick={() => {
                              setOpen(true);
                              setImageFile(application?.company_owner_biodata);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {' '}
                          <CommonTypoGraphy
                            name='View Passport'
                            onClick={() => {
                              setOpen(true);
                              setImageFile(application?.company_owner_passport);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CommonTypoGraphy
                            name='View Signature'
                            onClick={() => {
                              setOpen(true);
                              setImageFile(
                                application?.company_owner_signature
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant='body1'
                fontWeight='bold'
                color='text.primary'
                display='inline'
                sx={{ pr: 1 }}
                gutterBottom
              >
                No Data found
              </Typography>
            )}
          </CardContent>
        </Card>
      ) : (
        <PageLoader />
      )}{' '}
      <>
        <ModalsWrapper
          modalData={{ title: 'View document' }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default SingleOwnershipChangeApplication;
