import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { IBusinessLicense } from '../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes';
import { showToast } from '../../../Toaster/Toaster';
import { ICompanyBusinessLicense } from '../../../rest/types';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateCompanyBusinessLicenseMutation } from '../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation';
import { API_ENDPOINTS } from '../../../rest/utils/api-endpoints';
import FileValidation from '../../../Components/FileValidation/FileValidation';

interface IProps {
  memberId: number;
  companyBusinessLicense: ICompanyBusinessLicense;
}

const CompanyBusinessLicense = ({ companyBusinessLicense }: IProps) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const [businessLicenses, setBusinessLicense] = useState<IBusinessLicense>({
    hajj_license: companyBusinessLicense.hajj_license,
    umrah_license: companyBusinessLicense.umrah_license,
    recruting_license: companyBusinessLicense.recruting_license,
    outbound: companyBusinessLicense.outbound,
    inbound: companyBusinessLicense.inbound,
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyBusinessLicenseMutation(data);
    },
    onSuccess: () => {
      showToast('successfully updated', 'success');
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, 'error');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_BUSINESS_LICENSE],
      });
    },
  });
  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...businessLicenses };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === 'object') {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutation.mutate(formData);
  };
  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid
        container
        spacing={3}
        p={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid item xs={12} md={6}>
          <Typography variant='h6' gutterBottom>
            Other business license
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              textAlign: 'center',
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.hajj_license === 1 ? true : false
                    }
                  />
                }
                label='Hajj'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.umrah_license === 1 ? true : false
                    }
                  />
                }
                label='Umrah'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.recruting_license === 1 ? true : false
                    }
                  />
                }
                label='Recruiting'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Box>
            {businessLicenses.hajj_license ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Member organization Hajj license ( max size 5mb )'
                  fieldName={`hajj_license_file`}
                />
              </Box>
            ) : (
              ''
            )}
            {businessLicenses.umrah_license ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Member organization Umrah license ( max size 5mb )'
                  fieldName={`umrah_license_file`}
                />
              </Box>
            ) : (
              ''
            )}
            {businessLicenses.recruting_license ? (
              <Box>
                <FileValidation
                  mt={'0'}
                  isRequired
                  register={register}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  label='Member organization Recruiting license ( max size 5mb )'
                  fieldName={`recruting_license_file`}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant='h6' gutterBottom>
            Involved in tourism activities
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              pl: { xs: 5, md: 0 },
              // justifyContent: 'space-evenly',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.inbound === 1 ? true : false
                    }
                  />
                }
                label=' Inbound'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      inbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      inbound: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.outbound === 1 ? true : false
                    }
                  />
                }
                label='Outbound'
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      outbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      outbound: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ pt: 5, textAlign: 'end', mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Loading..
          </Button>
        ) : (
          <Button
            type='submit'
            variant='contained'
            disableElevation
            className='add-remove-button'
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CompanyBusinessLicense;
